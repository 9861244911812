<template>
    <div class="card">
        <div class="text-800 mb-1">
            <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                <AppInfoManual nomeTelaDoManual="acesso-soc-form" />
                <h3 class="ml-1">Acesso ao SOC</h3>
            </div>
        </div>
        <div class="grid p-fluid m-5">
            <div class="field col-6 pl-0">
                <label for="customer_id">Cliente</label>
                <DropdownCliente
                    id="customer_id"
                    :filter="true"
                    v-model="form.customer"
                    :options="customers"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Escolha"
                    :loading="loadingCustomers"
                    :class="{ 'p-invalid': submitted && !form.customer }"
                />
                <small class="p-error" v-if="submitted && !form.customer">Campo obrigatório.</small>
            </div>
            <div class="field col-6 pr-0">
                <label for="telaSoc">Tela do SOC</label>
                <Dropdown id="telaSoc" v-model="form.telaSoc" :options="telasSoc" optionLabel="label" optionValue="value" />
            </div>
            <Button label="Acessar SOC" icon="pi pi-external-link" autofocus :loading="loadingAcessarSoc" @click="acessarSoc" />
        </div>
    </div>
</template>

<script>
import { getProfile } from '../../services/auth';
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';
import DropdownCliente from '../clientes/components/DropdownCliente.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';
import { AbrePagina } from '../../utils/navegador/AbrePaginaStrategies';

export default {
    components: { DropdownCliente, AppInfoManual },
    data() {
        return {
            tenantCustomers: [],
            submitted: false,
            customers: [],
            loadingCustomers: false,
            loadingAcessarSoc: false,
            telasSoc: [
                { label: 'Tela Inicial', value: '' },
                { label: 'Funcionário', value: '232' },
                { label: 'Unidade', value: '231' },
                { label: 'Setor', value: '220' },
                { label: 'Cargo', value: '225' },
                { label: 'Entrega de EPI', value: '268' },
                { label: 'Cadastro de Extintor', value: '208' },
                { label: 'Visualizar Hierarquia', value: '163' }
            ],
            form: {
                telaSoc: ''
            }
        };
    },
    mounted() {
        this.$serviceCustomer = new BaseService('customers/combo');
        this.loadCustomers();
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        async loadCustomers() {
            this.loadingCustomers = true;
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
            this.loadingCustomers = false;
        },
        async acessarSoc() {
            try {
                this.submitted = true;
                if (!this.form.customer) {
                    this.$toast.add({ severity: 'error', summary: 'Campo obrigatório não preenchido! ', life: 3000 });
                    return;
                }
                this.loadingAcessarSoc = true;

                const usuario = await getProfile();
                if (!usuario.tokenSoc) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro ao acessar o SOC! Usuário não possuí acesso.',
                        detail: 'Solicite ao responsável inserir o token de segurança ao seu usuário.',
                        life: 5000
                    });
                    this.loadingAcessarSoc = false;
                    return;
                }

                const authTokenDto = {
                    tokenSoc: usuario.tokenSoc,
                    userId: usuario.id,
                    customer: {
                        id: this.form.customer.id
                    },
                    telaSoc: this.form.telaSoc
                };

                const url = await getClientBase().patch(`/users/acessarSoc/${usuario.id}`, authTokenDto);
                this.loadingAcessarSoc = false;
                const pagina = new AbrePagina(this.$toast);
                await pagina.abreNovaAbaOuAbaAtual(url.data);
            } catch (err) {
                this.loadingAcessarSoc = false;
                const message = err?.response?.data?.message ?? err?.message;
                this.$toast.add({ severity: 'error', summary: `Erro ao acessar o SOC! ${message}.`, life: 3000 });
            }
        }
    }
};
</script>
