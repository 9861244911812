import { delay } from '../Delay';

export class AbrePagina {
    estrategias = [NovaAba().PadraoStrategy, NovaAba().CriandoElementoStrategy];
    $toast = null;

    constructor(toast) {
        this.$toast = toast;
    }

    async abreNovaAbaOuAbaAtual(url, specs) {
        for (const estrategia of this.estrategias) {
            const abriu = await estrategia.abrir(url, specs);
            if (abriu) {
                return abriu;
            }
        }
        this.$toast.add({
            severity: 'error',
            summary: 'Seu navegador está bloqueando abrir a página em uma nova aba. Redirecionando...',
            life: 3000
        });
        await delay(4000);
        window.location.href = url;
        return false;
    }
}

export function NovaAba() {
    class PadraoStrategy {
        static async abrir(url, specs) {
            const aba = window.open(url, '_blank', specs);
            aba?.focus();
            return aba;
        }
    }
    class CriandoElementoStrategy {
        static async abrir(url) {
            await delay(2000);
            const paginaDuon = document;
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            paginaDuon.body.appendChild(link);
            link.click();
            paginaDuon.body.removeChild(link);

            await delay(1000);
            const abaNovaAbriu = !paginaDuon.hasFocus();
            return abaNovaAbriu;
        }
    }
    return { PadraoStrategy, CriandoElementoStrategy };
}
